<template>  
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 font-weight-bold flex-column" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        <div class="grey--text text--darken-3 text-uppercase">
          Consultar saldos
        </div>
        <!-- <div class="grey--text text--darken-2" :style="($vuetify.breakpoint.xs ? 'font-size: 16px;' : 'font-size: 18px;')">
          <b>Fecha actual</b>: {{ `${$dayjs().format('DD [de] MMMM [del] YYYY')}` }}
        </div> -->
      </v-card-title>
    </v-card>

<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->
    <v-card class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">

        <v-col cols="12" md="5" sm="5" xs="12"> 
          <v-select
            :label="`Consultar por...`"
            :items="saldo_select"
            :hint="``"
            persistent-hint
            v-model="tipoSaldo_selected"
          ></v-select>
        </v-col> 

      </v-row>
    </v-card>
<!-- FILTROS REPORTE VIGENCIA DE CONTRATOS -->

    <DataTable
      id="saldos-table"
      :headers="headers"
      :title="`Total: ${formatCurrency(total_saldos)}`"
      :subtitle="`Saldo ${tipoSaldo_selected || ''}`"
      :items="saldosTable.items"
      :loading="tableLoading"
      :searchEnabled="true"
      :showToolbar="true"
      :showSelect="true"
      :showExportExcel="true"
      :unselectAllCheckboxes="clearSelectedCheckboxes"
    >
      <template v-slot:showExportExcel="{ rowValues }">
        <a id="exportarExcel">
          <download-excel
            :header="`
              Reporte saldo ${tipoSaldo_selected}
              | Total: 
              ${tipoSaldo_selected === TIPOS_SALDO.ACREEDOR
                ? formatCurrency(Math.abs(
                    rowValues.filter(item => item.saldo_a_favor)
                      .reduce((acc, val) => acc + +val.saldo_a_favor, 0)
                  ))
                : formatCurrency(
                    rowValues.filter(item => item.saldo_en_contra_saldos)
                      .reduce((acc, val) => acc + +val.saldo_en_contra_saldos, 0)
                  )
              }
            `"
            :name="`Reporte_Saldo_${tipoSaldo_selected}.xls`"
            :export-fields="reporte_saldo_export_fields"
            :data="rowValues"
            type="xls"
            :default-value="''"
            :before-generate="() => {} /*clearSelectedCheckboxes = true*/"
            :before-finish="() => {} /*clearSelectedCheckboxes = false*/"
          >
            Exportar a Excel
          </download-excel>
        </a>
      </template>
    </DataTable>

  </v-container>
</template>

<script>
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { APP_DEBUG_MODE as APP_DEBUG, TIPOS_SALDO } from "@/utils/Constants";
import { formatCurrency } from "@/utils/Functions";
import { actions, getters, STORE_NAMESPACES } from "@/store";
import { ref, watch, computed, onBeforeUnmount } from "vue";

export default {
  components: {
    DataTable,
  },
  setup(props, ctx) {
    const { APP, PLANES_CLIENTE } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onBeforeUnmount(() => {
      APP_DEBUG_MODE && console.log("[Reporte_Saldos]: PlanesCliente unmount => SET_DEFAULT()");

      actions[PLANES_CLIENTE].SET_DEFAULT();
    });

    const clearSelectedCheckboxes = ref(false);
    const tipoSaldo_selected = ref("");

    const reporte_saldo_export_fields = computed(() => {
      return {
        "Póliza": "poliza",
        "Nombre del plan": "plan_catalog_data.nombre",
        "Cliente": "nombre_completo_cliente_saldos",
        "Moneda": "moneda",
        "Forma de pago": "forma_pago",
        "Saldo": {
          field: (tipoSaldo_selected.value === TIPOS_SALDO.ACREEDOR 
            ? "saldo_a_favor" 
            : "saldo_en_contra_saldos"),
          callback: (value) => {
            return (tipoSaldo_selected.value === TIPOS_SALDO.ACREEDOR 
            ? `${formatCurrency(Math.abs(value))}` 
            : `${formatCurrency(value)}`);
          }
        }
      }
    });

    const forma_pago_header = ref("Trimestre");
    const headers = computed(() => {
      return [
        { text: "No. póliza", value: "poliza", align: "center", sortable: true },
        { text: "Nombre del plan", value: "plan_catalog_data.nombre" },
        { text: "Cliente", value: "nombre_completo_cliente_saldos" },
        { text: "Moneda", value: "moneda" },
        { text: "Forma de pago", value: "forma_pago" },
        
        (tipoSaldo_selected.value === TIPOS_SALDO.ACREEDOR
          ? { text: "Saldo acreedor", value: "saldo_a_favor" }
          : { text: "Saldo deudor", value: "saldo_en_contra_saldos" }),
      ]
    });

    const tableLoading = getters[APP].getLoading;
    const total_saldos = ref(0);
    const saldosTable = useDataTable(getters[PLANES_CLIENTE].getAll, {});

    const saldo_select = [
      { text: `Saldo acreedor`, value: TIPOS_SALDO.ACREEDOR },
      { text: `Saldo deudor`,   value: TIPOS_SALDO.DEUDOR }
    ];

    watch(() => tipoSaldo_selected.value, (tipo_saldo) => {
      clearSelectedCheckboxes.value = true;
      actions[PLANES_CLIENTE].FETCH_SALDOS(tipo_saldo, (totalSaldo) => {
        total_saldos.value = totalSaldo;
        clearSelectedCheckboxes.value = false;
      });
    });
    
    return {
      TIPOS_SALDO,

      reporte_saldo_export_fields,
      headers,

      tipoSaldo_selected,
      saldo_select,
      saldosTable,
      total_saldos,
      clearSelectedCheckboxes,
      
      tableLoading,
      formatCurrency,
    };
  }
};
</script>

<style lang="scss">

// #saldos-table {
//   tbody tr {
//     cursor: pointer;
//   }
// }
  
</style>