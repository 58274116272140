var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"d-flex mb-2 justify-center",staticStyle:{"border-radius":"24px 24px 8px 8px"},attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-4 px-6 font-weight-bold flex-column",style:((_vm.$vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;'))},[_c('div',{staticClass:"grey--text text--darken-3 text-uppercase"},[_vm._v(" Consultar saldos ")])])],1),_c('v-card',{staticClass:"mt-5 mb-5",attrs:{"elevation":"1"}},[_c('v-row',{staticClass:"v-list-item"},[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5","xs":"12"}},[_c('v-select',{attrs:{"label":`Consultar por...`,"items":_vm.saldo_select,"hint":``,"persistent-hint":""},model:{value:(_vm.tipoSaldo_selected),callback:function ($$v) {_vm.tipoSaldo_selected=$$v},expression:"tipoSaldo_selected"}})],1)],1)],1),_c('DataTable',{attrs:{"id":"saldos-table","headers":_vm.headers,"title":`Total: ${_vm.formatCurrency(_vm.total_saldos)}`,"subtitle":`Saldo ${_vm.tipoSaldo_selected || ''}`,"items":_vm.saldosTable.items,"loading":_vm.tableLoading,"searchEnabled":true,"showToolbar":true,"showSelect":true,"showExportExcel":true,"unselectAllCheckboxes":_vm.clearSelectedCheckboxes},scopedSlots:_vm._u([{key:"showExportExcel",fn:function({ rowValues }){return [_c('a',{attrs:{"id":"exportarExcel"}},[_c('download-excel',{attrs:{"header":`
              Reporte saldo ${_vm.tipoSaldo_selected}
              | Total: 
              ${_vm.tipoSaldo_selected === _vm.TIPOS_SALDO.ACREEDOR
                ? _vm.formatCurrency(Math.abs(
                    rowValues.filter(item => item.saldo_a_favor)
                      .reduce((acc, val) => acc + +val.saldo_a_favor, 0)
                  ))
                : _vm.formatCurrency(
                    rowValues.filter(item => item.saldo_en_contra_saldos)
                      .reduce((acc, val) => acc + +val.saldo_en_contra_saldos, 0)
                  )
              }
            `,"name":`Reporte_Saldo_${_vm.tipoSaldo_selected}.xls`,"export-fields":_vm.reporte_saldo_export_fields,"data":rowValues,"type":"xls","default-value":'',"before-generate":() => {} /*clearSelectedCheckboxes = true*/,"before-finish":() => {} /*clearSelectedCheckboxes = false*/}},[_vm._v(" Exportar a Excel ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }